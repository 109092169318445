import styled from 'styled-components';

export const Foot = styled.div`
    justify-content: space-between;
    align-items: center;
    z-index: 11;
    bottom: 0;
    position: absolute;
    width: 100%;
    background-color: #FAF9F6;
    color: #3a3b3c;
    height: 60px;
    display: block;
`;