import React, { Component } from "react";
import '../../styles/style.css';
import Footer from '../../components/Footer'

class Resume extends Component {
  render () {
    return (

        <section className="resume">

            <h1>
                Coming Soon...
            </h1>
  
        <Footer/>  
        </section>
    )
  }
}

export {Resume};