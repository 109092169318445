import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HamLink = styled(Link)`

    font-size: 18px;
    padding: 13px 15px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-weight: 900;

`;